<template>
  <form action="" @submit.stop.prevent="() => {}">
    <!-- <b-form-group v-if="isOrganized">
      <b-form-radio-group v-model="selectFamilyAll" name="radio-sub-category">
        <b-form-radio value="all">{{
          $t('search-tab.all-other')
        }}</b-form-radio>
        <b-form-radio value="family">{{
          $t('search-tab.family-tours')
        }}</b-form-radio>
      </b-form-radio-group>
    </b-form-group> -->

    <div class="form-row" v-if="isOdysseySite" :class="{ 'col-md-12 mt-1': type!=='tabs' }">
      <b-form-group id="input-group-2" class="agent-select" label-for="input-2" :class="type==='tabs' ? 'col-md-3': 'col-md-12'">
        <v-select
          v-model="agent"
          :reduce="agent => agent.value"
          :options="agentList"
          dir="rtl"
          :clearable="false"
          :searchable="true"
          :disabled="loginOdyAgentCode!==changeEnableAgentCode"
        >
        </v-select>
      </b-form-group>
    </div>
    <div class="form-row desktop_form" v-if="type === 'tabs'">
      <div class="form-group col-md-3">
        <Select
          @changeDestination="changed"
          :type="type"
          @optionSelect="optionSelect"
          :category="category"
          :disabled="disableDestination"
          :hotDestinations="hotDestinations"
        />
      </div>
      <div class="form-group col-md-3">
        <app-datepicker
          :availableDates="availableDates"
          :calendarShow="destinationChanged"
          @finalDateSelect="finalDateSelect"
          @dates="chooseDate"
          :destinationText="destinationText"
          :disabled="disableCalendar"
        ></app-datepicker>
      </div>
      <div class="form-group col-md-3">
        <occupancy-pax
          @changeBodyCount="changeCount"
          :dropdownShow="dropdownShow"
          :disabled="disableOccupancy"
        />
      </div>
      <div class="form-group col-md-3 d-flex" style="height:3rem;">
        <button
          class="btn flex-fill"
          @click="submitSearch"
          :disabled="searchDisable"
        >
          {{ categoryId ? $t(`search-tab.search_${categoryId}`) : '' }}
          <b-spinner v-if="isLoading"></b-spinner>
          <i class="fas fa-search ml-3" v-else></i>
        </button>
      </div>
    </div>

    <!-- <MobileSearchForm/> -->
    <div class="card card-body boxSideSearch border-0" :class="{ mobile_form: type==='tabs' }">
      <div class="form-row">
        <div class="form-group col-12">
          <Select
            @changeDestination="changed"
            :type="type"
            @optionSelect="optionSelect"
            :category="category"
            :disabled="disableDestination"
            :hotDestinations="hotDestinations"
          />
        </div>
        <div class="form-group col-12">
          <div class="input-group">
            <app-datepicker
              :availableDates="availableDates"
              :calendarShow="destinationChanged"
              @dates="chooseDate"
              type="side"
              :destinationText="destinationText"
              :disabled="disableCalendar"
            ></app-datepicker>
          </div>
        </div>
        <div class="form-group col-4">
          <b-form-select class="form-control" v-model="countAdult" :disabled="disableOccupancy">
            <template #first>
              <b-form-select-option :value="0" disabled>{{ $t('search-tab.adult') }}</b-form-select-option>
            </template>
            <option v-for="inx in 6" :key="inx" :value="inx - 1">{{ inx - 1 }}</option>
          </b-form-select>
        </div>
        <div class="form-group col-4">
          <b-form-select class="form-control" v-model="countChild" :disabled="disableOccupancy">
            <template #first>
              <b-form-select-option :value="0" disabled>{{ $t('search-tab.child') }}</b-form-select-option>
            </template>
            <option v-for="inx in 6" :key="inx" :value="inx - 1">{{ inx - 1 }}</option>
          </b-form-select>
        </div>
        <div class="form-group col-4">
          <b-form-select class="form-control" v-model="countInfant" :disabled="disableOccupancy">
            <template #first>
              <b-form-select-option :value="0" disabled>{{
                $t('search-tab.infant')
              }}</b-form-select-option>
            </template>
            <option v-for="inx in 6" :key="inx" :value="inx - 1">{{ inx - 1 }}</option>
          </b-form-select>
        </div>
        <div class="form-group d-flex">
          <button
            class="btn flex-fill btn-outline-dark btn-search mobile"
            @click="submitSearch"
            :disabled="searchDisable"
          >
            {{ categoryId ? $t(`search-tab.search_${categoryId}`): '' }}
            <b-spinner v-if="isLoading" small></b-spinner>
            <i class="fas fa-search" v-else></i>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import vSelect from 'vue-select';
import {
  BFormSelect,
  BFormSelectOption,
  BSpinner,
  BFormGroup,
} from 'bootstrap-vue';
import TemplateSearchTab from './TemplateSearchTab';

export default {
  name: 'SearchTabTheme0',
  components: {
    OccupancyPax: () => import('@/components/atoms/occupancyPax/OccupancyPaxTheme0'),
    Select: () => import('@/components/atoms/searchSelect/SearchSelectTheme0'),
    AppDatepicker: () => import('@/components/atoms/customDatepickerRange/CustomDatepickerRangeTheme0'),
    BFormSelect,
    BFormSelectOption,
    BSpinner,
    BFormGroup,
    vSelect,
  },
  extends: TemplateSearchTab,
};
</script>

<style scoped>
@media (max-width: 479px) {
  .mobile_form {
    display: block;
  }
  .desktop_form {
    display: none;
  }
}
.custom-control {
  padding-right: 1rem;
}
</style>

<style>
.custom-control-input {
  left: auto;
  right: 0;
}
.filter-tab .custom-control-label::before,
.filter-tab .custom-control-label::after {
  right: -1.5rem;
  left: auto;
}
.vs__dropdown-toggle {
  background: #e9ecef !important;
}
</style>
